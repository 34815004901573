<template>
  <div class="auth-window auth-window_reset">
    <div class="auth-window__form-section">
      <header class="auth-window__form-header">
        <h3 class="auth-window__form-title">Reset</h3>
      </header>

      <form @submit.prevent="submitHandler" class="auth-window__form auth-window__form_login">
        <div
          class="auth-window__form-input"
          :class="{
            'input_invalid' : ($v.email.$dirty && !$v.email.required) || ($v.email.$dirty && !$v.email.email), 
            'input_valid' : !$v.email.$invalid 
          }"
        >
          <input type="text" v-model.trim="email" placeholder="Email">

          <small
            class="helper-text invalid"
            v-if="$v.email.$dirty && !$v.email.required"
          >This field must not be empty</small>
          <small
            class="helper-text invalid"
            v-else-if="$v.email.$dirty && !$v.email.email"
          >Invalid Email</small>
        </div>

        <div class="auth-window__action-row auth-window__action-row_login">
          <!-- <div class="captha captha_login">Captha</div> -->
          <vue-recaptcha
            class="captha captha_login"
            ref="recaptcha"
            :sitekey="sitekey"
            @verify="onVerify"
            @expired="onExpired"
          />

          <button class="action-auth-btn action-auth-btn_login">Reset</button>

          <span class="auth-window__change-action">
            Back to
            <router-link class="simple-link simple-link_underline" to="/login">Login.</router-link>
          </span>
        </div>
      </form>
    </div>

    <!-- <div class="auth-window__slider">Image for reset here</div> -->
  </div>
</template>

<script>
import { email, required, minLength } from "vuelidate/lib/validators";
import VueRecaptcha from "vue-recaptcha";
import { mapState, mapMutations, mapActions } from "vuex";
import { ToastProgrammatic as Toast } from "buefy";

export default {
  components: { VueRecaptcha },
  data: () => ({
    email: "",
    recaptcha: "",
    sitekey: "6LeAyLYUAAAAANWXmSpKGVpGRb1TNOW09mUmfpLR"
  }),
  validations: {
    email: { email, required }
  },
  computed: {
    ...mapState("auth", ["resetErrorCode"])
  },
  methods: {
    ...mapMutations("auth", ["clearResetError"]),

    async submitHandler() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }

      const formData = {
        email: this.email,
        recaptcha: this.recaptcha
      };

      await this.$store.dispatch("auth/resetPassword", formData);

      if (this.resetErrorCode == 400) {
        this.$toasted.show(`Email does not exist`, {
          theme: "toasted-primary",
          position: "top-right",
          duration: 10000,
          className: "danger-notification"
        });

        this.clearResetError();
        this.clearForm();
      } else {
        this.$toasted.show(`Letter with new password send to your Email`, {
          theme: "toasted-primary",
          position: "top-right",
          duration: 10000,
          className: "success-notification"
        });

        this.$router.push("/login");
      }
    },
    clearForm() {
      this.email = "";
      this.resetRecaptcha();
    },
    onSubmit: function() {
      this.$refs.invisibleRecaptcha.execute();
    },
    onVerify: function(response) {
      this.setTokenCaptcha(response);
    },
    onExpired: function() {
      this.setTokenCaptcha("");
      this.resetRecaptcha();
    },
    resetRecaptcha() {
      this.setTokenCaptcha("");
      this.$refs.recaptcha.reset(); // Direct call reset method
    },

    setTokenCaptcha(recaptchaToken) {
      this.recaptcha = recaptchaToken;
    }
  }
};
</script>


